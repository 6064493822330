<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-button title="Back|Précédent" :value="block.backButton" v-bind="componentProps"/>
        <properties-component-button title="Next|Suivant" :value="block.nextButton" v-bind="componentProps"/>
        <properties-component-text title="Title|Titre" :value="block.title" v-bind="componentProps"/>
        <properties-component-text title="Subtitle|Sous-titre" :value="block.subtitle" v-bind="componentProps"/>

        <template #layout>
            <b-form-group label-cols="8" :label="'Height|Hauteur'|tr">
                <e-input v-model="block.height" state="disabled" placeholder="auto"/>
                <span class="unit">px</span>
            </b-form-group>
            <b-form-group label-cols="8" :label="'Button Width|Largeur bouton'|tr">
                <e-input v-model="block.buttonWidth" state="disabled" placeholder="auto"/>
                <span class="unit">%</span>
            </b-form-group>

            <b-form-group>
                <b-checkbox v-model="block.buttonFill">
                    <span v-tr>Fill Button Space|Remplir l'espace des boutons</span>
                </b-checkbox>
            </b-form-group>
        </template>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-block-navigation #hot-reload-debug
export default {
    name: `properties-block-navigation`,
    components: {EInput, PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
